import { useState } from 'react'

import { $data } from '../../../store/data'
import { setStep, prevStep } from '../../../store/step'
import SendPayment from './SendPayment'
import CryptoPayment from './CryptoPayment'
import Loading from './Loading'

export default function Payment() {
  const [method, setMethod] = useState(null)
  const [loading, setLoading] = useState(false)

  function goTo(i) {
    setStep(i)
  }

  function handleLink(pm) {
    const base64 = btoa(JSON.stringify($data.get()))
    setLoading(true)
    
    fetch(`${import.meta.env.PUBLIC_API_URL}/links?q=${base64}`)
      .then(res => res.json())
      .then(res => {
        window.location.href = res[pm]
      })
  }

  return (
    <>
      <div className="bg-neutral-100 pt-16 pb-8 sm:py-20">
        <div className="container px-6 mx-auto">
          <div className="relative max-w-[518px] mx-auto mb-16">
            <div className="relative grid grid-cols-3 items-center z-10">
              <div>
                <span
                  className="w-8 h-8 text-neutral-50 bg-neutral-600 rounded-full flex items-center justify-center mb-4 cursor-pointer"
                  onClick={() => goTo(1)}
                >
                  1
                </span>
                <h6 className="uppercase opacity-0 sm:opacity-50">Choose Plan</h6>
              </div>

              <div className="flex flex-col justify-center items-center">
                <span
                  className="w-8 h-8 text-neutral-50 bg-neutral-600 rounded-full flex items-center justify-center mb-4 cursor-pointer"
                  onClick={() => goTo(2)}
                >
                  2
                </span>
                <h6 className="uppercase opacity-0 sm:opacity-50">Sign Up</h6>
              </div>

              <div className="flex flex-col justify-end items-end">
                <span className="w-8 h-8 text-neutral-600 bg-secondary-menthol rounded-full flex items-center justify-center mb-4">3</span>
                <h6 className="uppercase text-right" style={{ textWrap: 'nowrap' }}>Payment Method</h6>
              </div>
            </div>

            <hr className="absolute top-4 left-0 right-0 w-full border-t-2 border-neutral-600"></hr>
          </div>

          <div className="flex flex-col sm:flex-row justify-center gap-8 mb-10">
            <button
              onClick={() => handleLink('us_bank_account')}
              disabled={loading}
              className="text-neutral-100 leading-[16px] tracking-[0.16px] bg-secondary-jungle hover:bg-jungle-300 py-6 px-10 rounded-2xl w-full h-[144px] max-w-[460px] transition duration-150 ease-in-out focus:outline-none focus:ring focus:ring-jungle-300/50"
            >
              {loading ? <Loading /> :
                <>
                  Continue with
                  <span className="text-[40px] leading-[40px] text-neutral-100 mt-4 block">
                    ACH
                  </span>
                </>
              }
            </button>

            <button
              onClick={() => handleLink('card')}
              disabled={loading}
              className="text-neutral-100 leading-[16px] tracking-[0.16px] bg-secondary-jungle hover:bg-jungle-300 py-6 px-10 rounded-2xl w-full h-[144px] max-w-[460px] transition duration-150 ease-in-out focus:outline-none focus:ring focus:ring-jungle-300/50"
            >
              {loading ? <Loading /> :
                <>
                  Continue with
                  <span className="text-[40px] leading-[40px] text-neutral-100 mt-4 block">
                    Credit Card
                  </span>
                </>
              }
            </button>
          </div>

          <p className="text-neutral-600 font-display leading-[20px] text-left md:text-center flex items-center justify-center gap-2 md:gap-[6px]">
            <i className="ph-fill ph-info text-menthol-900"></i>
            Paying with credit card adds 3% per credit card transition
          </p>
        </div>
      </div>

      {/* Send Payment */}
      <div className="bg-white pt-16 pb-20">
        <div className="container px-6 max-w-[928px] mx-auto">
          
          <div className="grid grid-cols-1 gap-12 mb-12">
            <SendPayment
              method={method}
              setMethod={setMethod}
              loading={loading}
              setLoading={setLoading}
            />

            <CryptoPayment
              method={method}
              setMethod={setMethod}
              loading={loading}
              setLoading={setLoading}
            />
          </div>

          <div className="bg-neutral-100 rounded-[18px] p-4 sm:p-8 mb-12">
            <h3 className="text-neutral-600 text-xl text-center sm:text-left leading-[24px] flex flex-col sm:flex-row justify-center items-center gap-4 mb-8">
              <svg width="24" height="24" viewBox="0 0 49 49" fill="none">
                <path id="Vector" d="M4.93083 38.607C8.45244 35.0514 11.9825 31.4874 16.0249 27.4017C14.0009 27.4017 12.6412 27.4017 11.2844 27.4017C8.09774 27.4017 4.91394 27.3792 1.72731 27.4215C0.578775 27.4356 0.125553 27.1057 0.0720676 25.8312C-0.141875 20.7897 -0.198176 20.7841 4.72814 20.7559C7.63326 20.7389 10.5384 20.7728 13.4407 20.7615C14.0741 20.7615 14.7497 20.9532 15.5435 20.3047C12.0022 16.735 8.47777 13.1851 4.92238 9.60134C5.92172 8.08156 7.32361 7.10033 8.41584 5.83431C9.21813 4.90382 9.83462 4.9574 10.6819 5.83431C13.1507 8.403 15.7124 10.8843 18.2037 13.4332C18.854 14.0987 19.3776 14.8826 19.8759 15.5057C21.106 14.8854 20.726 14.1325 20.7316 13.5601C20.7711 9.61827 20.7879 5.67357 20.7316 1.73171C20.7148 0.530545 21.092 0.144264 22.3193 0.0850517C27.4849 -0.163077 27.4793 -0.205383 27.4765 4.93764C27.4765 8.37197 27.4765 11.8063 27.4765 15.9202C31.4738 11.9558 35.0123 8.44528 38.6184 4.86998C40.4313 6.29953 41.8867 7.85315 43.5757 9.83536C39.9161 13.343 36.2847 16.8224 32.1748 20.7615C36.417 20.7615 40.0287 20.7417 43.6376 20.7671C49.1523 20.8038 48.0206 20.1271 48.1332 25.428C48.1726 27.3425 48.0938 27.3848 46.0642 27.3989C41.6952 27.4271 37.3263 27.4074 32.2395 27.4074C36.2087 31.4254 39.7163 34.981 43.1788 38.4858C43.1112 38.7706 43.1253 38.9933 43.0239 39.0948C41.6389 40.4708 40.496 42.8393 38.7648 42.9041C37.0054 42.969 35.8625 40.5469 34.4634 39.2076C32.2508 37.0872 30.1564 34.8484 27.468 32.8437C27.468 34.386 27.468 35.9283 27.468 37.4707C27.468 40.5695 27.4483 43.6655 27.4793 46.7642C27.4877 47.6834 27.209 48.1684 26.2069 48.1374C24.8951 48.0951 23.5805 48.0669 22.2743 48.1515C20.9766 48.2361 20.7176 47.6073 20.7316 46.4738C20.7795 43.1889 20.7485 39.9041 20.7485 36.6164C20.7485 35.4095 20.7485 34.2027 20.7485 32.9677C19.6901 32.838 19.3663 33.6642 18.8822 34.1379C16.2022 36.763 13.5814 39.4472 10.9128 42.0836C9.53623 43.4427 9.47711 43.4512 8.08086 42.0301C7.03929 40.9699 5.84008 40.0507 4.93083 38.607Z" fill="#FF613A"/>
              </svg>
              Your first month will be prorated depending on the day of your kickoff call
            </h3>

            <p className="text-neutral-600 text-xl text-center font-light leading-[26px] max-w-[688px] mx-auto mb-8">
              For example, if you sign up for a Pro Plan at $3,595 / mo and your kickoff date is on January 15th, your proration would be:<br/>
              <span className="font-normal">
                $3,595 / 31 days in January * 17 days = $1,971
              </span>
            </p>
            <p className="text-neutral-600 text-xl text-center font-light leading-[24px] max-w-[688px] mx-auto mb-8">
              From there, billing will be automated on the first of each month and you will receive your invoice to your billing email.
            </p>

            <div className="bg-secondary-misty px-4 sm:px-[30px] py-[18px] rounded-lg sm:rounded-none">
              <p className="text-neutral-600 font-light text-base sm:text-xl text-center leading-[19.2px] sm:leading-[24px]">
                Credit card transactions have a 3% transaction fee. ACH transactions have no transaction fee.
              </p>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="button"
              onClick={prevStep}
              className="text-neutral-600 hover:text-neutral-50 text-center font-text font-semibold leading-[16px] tracking-[0.16px] uppercase hover:bg-neutral-600 border border-neutral-600 rounded-md h-[50px] w-full max-w-[300px] mx-auto transition duration-150 ease-in-out focus:outline-none focus:ring focus:ring-neutral-600/50"
            >
              Go back
            </button>
          </div>

        </div>
      </div>
    </>
  )
}
