import { map } from 'nanostores'

export const $data = map({
  plan: null,
  special: false,
  period: null,
  company: null,
  email: null,
  firstname: null,
  lastname: null,
  legal_company: null,
  billing_address: null,
  billing_email: null,
  additional_billing_email: null
})

export function setPlan (plan, period, special = false) {
  $data.set({ ...$data.get(), plan, period, special })
}