import Special from './Special'
import Tabs from './Tabs'

export default function Pricing({ opt }) {
  return (
    <>
      {opt ? <Special opt={opt} /> : <Tabs />}
    </>
  )
}
