// Nano Stores
import { useStore } from '@nanostores/react'
import { $step } from '../../store/step'
import { $data } from '../../store/data'

import Pricing from './Pricing/Index'
import Form from './Form'
import Payment from './Payment/Index'

export default function Setup({ opt }) {
  const step = useStore($step)

  const data = window.localStorage.getItem('tdp_data')

  if (data) {
    $data.set(JSON.parse(atob(data)))
  }

  return (
    <main className="bg-white">
      {step == 1 && <Pricing opt={opt} />}
      {step == 2 && <Form />}
      {step == 3 && <Payment />}
    </main>
  )
}
