import { setPlan } from '../../../store/data'
import { nextStep } from '../../../store/step'

export default function PricingSpecial({ opt }) {
  function handleSelect() {
    setPlan(`${opt.plan} ${opt.price}`, opt.period, true)
    nextStep()
  }

  return (
    <section className="bg-neutral-100 pt-14 pb-28">
      <div className="container px-6 mx-auto">
        <h3 className="text-blue-500 text-5xl text-left mb-10">
          <span className="text-blue-300">1/3</span> Pricing
        </h3>

        <div className="bg-neutral-50 w-full max-w-[642px] p-8 mx-auto rounded-2xl mb-20">
          <h4 className="font-display text-5xl text-blue-500 text-center leading-[52.8px] mb-6">
            Special offer for<br/>{opt.customer}
          </h4>

          <h5 className="text-blue-500 text-6xl leading-[66px] text-center uppercase mb-10">
            {opt.price}
            <span className="text-neutral-500 text-4xl block">
              {opt.period ? opt.period : 'per month'}
            </span>
          </h5>

          {opt.description &&
            <p className="text-neutral-600 text-center leading-[20px] flex items-center justify-center gap-2">
              <i className="ph-fill ph-info text-blue-300"></i>
              {opt.description}
            </p>
          }
        </div>

        <div className="flex items-center justify-center">
          <button type="button" onClick={handleSelect} className="text-neutral-50 font-text tracking-[0.16px] leading-[16px] uppercase bg-neutral-600 rounded-md py-[15px] px-10 w-full max-w-[300px]">
            Next: Sign up
          </button>
        </div>
      </div>
    </section>
  )
}
