import { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import Loading from './Loading'

import { $data } from '../../../store/data'

function isEmail(str) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const result = regex.test(str)
  return result
}

export default function SendPayment({ method, setMethod, loading, setLoading }) {
  const [validations, showValidations] = useState(false)
  const [data, setData] = useState({
    name: null,
    email: null
  })
  const [error, setError] = useState({
    name: false,
    email: false
  })
  const [focus, setFocus] = useState({
    name: false,
    email: false,
  })

  function handleFocus(event) {
    const { name } = event.target
    
    setFocus({ ...focus, [name]: true })
  }

  function handleBlur(event) {
    const { name } = event.target
    setFocus({ ...focus, [name]: false })
  }

  function handleInputChange(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    showValidations(true)

    if (!data.name || !data.email) {
      setError({
        name: !data.name,
        email: !data.email
      })
      return
    }

    setLoading(true)
    const base64 = btoa(JSON.stringify($data.get()))

    fetch(`${import.meta.env.PUBLIC_API_URL}/email-links`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: e.target.name.value,
        email: e.target.email.value,
        data: base64
      })
    })
    .then(res => {
      if (res.ok) {
        window.location.href = '/thank-you'
      }
    })
  }

  return (
    <Disclosure>
      {method !== 'SEND_PAYMENT' && (
        <Disclosure.Button className="w-full">
          <button onClick={() => setMethod('SEND_PAYMENT')} className="bg-neutral-50 border border-neutral-500 rounded-2xl p-4 sm:p-8 w-full">
            <h5 className="text-blue-500 text-xl text-left leading-[27px] flex items-center gap-3 mb-3">
              <i className="ph-bold ph-square text-neutral-600"></i>
              Is someone else responsible for payment?
            </h5>
            <p className="text-neutral-600 text-left leading-[20px]">
              No worries, enter in their email and we will reach out to them to continue signing up
            </p>
          </button>
        </Disclosure.Button>
      )}

      {method === 'SEND_PAYMENT' && (
        <Disclosure.Panel static>
          <form onSubmit={handleSubmit} className="relative p-6 md:p-8 border border-neutral-500 rounded-2xl">
            <button
              type="button"
              onClick={() => setMethod(null)}
              className="absolute right-8 top-8"  
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M19.2803 18.2198C19.35 18.2895 19.4053 18.3722 19.443 18.4632C19.4807 18.5543 19.5001 18.6519 19.5001 18.7504C19.5001 18.849 19.4807 18.9465 19.443 19.0376C19.4053 19.1286 19.35 19.2114 19.2803 19.281C19.2106 19.3507 19.1279 19.406 19.0369 19.4437C18.9458 19.4814 18.8482 19.5008 18.7497 19.5008C18.6511 19.5008 18.5536 19.4814 18.4625 19.4437C18.3715 19.406 18.2887 19.3507 18.2191 19.281L11.9997 13.0607L5.78031 19.281C5.63958 19.4218 5.44871 19.5008 5.24968 19.5008C5.05066 19.5008 4.85979 19.4218 4.71906 19.281C4.57833 19.1403 4.49927 18.9494 4.49927 18.7504C4.49927 18.5514 4.57833 18.3605 4.71906 18.2198L10.9394 12.0004L4.71906 5.78104C4.57833 5.64031 4.49927 5.44944 4.49927 5.25042C4.49927 5.05139 4.57833 4.86052 4.71906 4.71979C4.85979 4.57906 5.05066 4.5 5.24968 4.5C5.44871 4.5 5.63958 4.57906 5.78031 4.71979L11.9997 10.9401L18.2191 4.71979C18.3598 4.57906 18.5507 4.5 18.7497 4.5C18.9487 4.5 19.1396 4.57906 19.2803 4.71979C19.421 4.86052 19.5001 5.05139 19.5001 5.25042C19.5001 5.44944 19.421 5.64031 19.2803 5.78104L13.06 12.0004L19.2803 18.2198Z" fill="#252323"/>
              </svg>
            </button>

            <h4 className="text-blue-500 text-xl leading-[27px] flex items-center gap-3 mb-3">
              <i className="text-[#91DA3E] ph-fill ph-check-square cursor-pointer" onClick={() => setMethod(null)}></i>
              Is someone else responsible for payment?
            </h4>
            <p className="text-neutral-600 leading-[20px] mb-10">
              No worries, enter in their email and we will reach out to them to continue signing up
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-10">
              <div>
                <label htmlFor="name" className={`text-black ${focus.name ? 'text-blue-500' : null} ${error.name && !data.name ? 'text-error' : null} text-sm uppercase transition duration-150 ease-in-out block mb-4`}>
                  Name <span className="text-error">*</span>
                </label>    
                <input
                  id="name"
                  name="name"
                  type="text"
                  className={`${(error.name) ? 'invalid:border-error invalid:ring invalid:ring-error/50 valid:border-2 valid:shadow-valid valid:bg-[#F8F8F8]' : 'invalid:border-jungle-500'} bg-neutral-50 text-xl leading-[24px] border border-jungle-500 text-neutral-600 placeholder:text-neutral-300 placeholder:font-display placeholder:font-normal outline-none p-6 w-full rounded-sm hover:ring hover:ring-[rgb(102,102,102)]/50 focus:outline-none focus:ring focus:ring-[rgb(89,85,255)]/50 transition duration-150 ease-in-out`}
                  placeholder="Name"
                  autoComplete="off"
                  onChange={handleInputChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  disabled={loading}
                  required={error.name}
                />
                {(error.name && !data.name) && (
                  <p className="text-error leading-[20px] transition duration-150 ease-in-out mt-2">
                    This field is required to continue
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="email" className={`text-black ${focus.email ? 'text-blue-500' : null} ${error.email && !data.email ? 'text-error' : null} text-sm uppercase transition duration-150 ease-in-out block mb-4`}>
                  Email <span className="text-error">*</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type={validations ? 'email' : 'text'}
                  className={`${(error.email) ? 'invalid:border-error invalid:ring invalid:ring-error/50 valid:border-2 valid:shadow-valid valid:bg-[#F8F8F8]' : 'invalid:border-jungle-500'} bg-neutral-50 text-xl leading-[24px] border border-jungle-500 text-neutral-600 placeholder:text-neutral-300 placeholder:font-display placeholder:font-normal outline-none p-6 w-full rounded-sm hover:ring hover:ring-[rgb(102,102,102)]/50 focus:outline-none focus:ring focus:ring-[rgb(89,85,255)]/50 transition duration-150 ease-in-out`}
                  placeholder="email@company.io"
                  autoComplete="off"
                  onChange={handleInputChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  disabled={loading}
                  required={error.email}
                />
                {(error.email && !isEmail(data.email)) && (
                  <p className="text-error leading-[20px] transition duration-150 ease-in-out mt-2">
                    Please enter a valid email address
                  </p>
                )}
              </div>
            </div>

            <div>
              <button type="submit" disabled={loading} className="font-text text-neutral-50 leading-[16px] uppercase bg-blue-500 rounded-md py-6 px-10 w-full focus:outline-none focus:ring focus:ring-blue-500/50 transition duration-150 ease-in-out">
                {loading ? <Loading /> : 'Send Email'}
              </button>
            </div>
          </form>
        </Disclosure.Panel>
      )}
    </Disclosure>
  )
}
